import {useState} from 'react';

import {REPORT_MODULES_IDS} from '../../../../const';
import useProfile from '../../../providers/useProfile';
import useProjects from '../../../providers/useProjects';
import useReport from '../../../providers/useReport';
import useReportSummary from '../../../providers/useReportSummary';
import useWorkspaces from '../../../providers/useWorkspaces';

export function useIsModuleVisibleOrEnabled() {
  const [canRefreshReport, setCanRefreshReport] = useState(true);

  const {reports = [], selectedReport, selectedProject: project, isDesktopLayout, isReportEditable, alreadyTakenBudgetNames: budgets = [], spreadsheetDataLoaded} = useReport();
  const {profile} = useProfile();
  const {workspaces = [], isUserInWorkspace} = useWorkspaces();
  const {currentReportPageSummary} = useReportSummary();
  const {CONNECTORS_HAVING_IMPORT_DATA_BUTTON} = useProjects();

  const selectedReportId = selectedReport?.config?.id;
  const currentReport = reports.find(r => r.report_id === selectedReportId) || {};

  const isEditReportEnabled = () => {
    const reportWorkspace = workspaces.find(w => (w.reports || []).some(r => r.report_id === selectedReportId));
    return isReportEditable && isUserInWorkspace(profile?.id, reportWorkspace) && isDesktopLayout;
  };

  const isModuleVisible = module => {
    switch (module.id) {
      case REPORT_MODULES_IDS.EDIT_REPORT:
        return isEditReportEnabled();
      case REPORT_MODULES_IDS.EXPORT_REPORT:
      case REPORT_MODULES_IDS.REFRESH_REPORT:
        return true;
      case REPORT_MODULES_IDS.UPLOAD_DATA:
        return CONNECTORS_HAVING_IMPORT_DATA_BUTTON.includes(project?.connector);
      case REPORT_MODULES_IDS.EDIT_BUDGET:
      case REPORT_MODULES_IDS.DELETE_BUDGET:
        return budgets.length > 0 && spreadsheetDataLoaded;
      default:
        return currentReport?.[module.backendId] ?? false;
    }
  };

  const isModuleEnabled = module => {
    if (module.requiresSirenSelected && !project) {
      return false;
    }

    if (module.requiresSirenSelected && project && !project.is_owner) {
      return false;
    }

    if (module.id === REPORT_MODULES_IDS.UPDATE_REPORT_SUMMARY) {
      return currentReportPageSummary !== null;
    }

    if (module.id === REPORT_MODULES_IDS.REFRESH_REPORT) {
      return canRefreshReport;
    }

    return true;
  };

  return {isModuleVisible, isModuleEnabled, canRefreshReport, setCanRefreshReport};
}
